import { memo } from "react"
import { Container, Heading, Box, HStack, Text } from "@chakra-ui/react"
import { useSection } from "@app/hooks/useSection"
import { ProductCard } from "@components/ProductCard/ProductCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { CarouselOverflowWrapper, extraOverflowParentProps } from "@app/components/CarouselOverflowWrapper"
import { AlgoliaSearchProvider } from "@app/providers/algolia"

import type { Location } from "@root/types/global"
import type { WishListButtonProps } from "@components/WishList/WishListButton"

interface FeaturedProductsWrapperProps {
  title?: string
  bgColor?: string
  products?: Array<GatsbyTypes.SanityProduct>
  analytics?: any
  location: Location
  isRecommendations?: boolean
  button?: React.ReactNode
  isMultiple?: boolean
  wishListButtonProps?: WishListButtonProps
  isMinimal?: boolean
}

const FeaturedProductsWrapper: React.FC<FeaturedProductsWrapperProps> = ({
  title,
  bgColor = "brand.offWhite",
  products,
  analytics,
  button,
  location,
  isRecommendations = false,
  isMultiple = false,
  wishListButtonProps,
  isMinimal = false,
}) => {
  const { handleTrackingClick } = useSection({ analytics })

  return (
    <AlgoliaSearchProvider key="featured-products">
      <Box
        bgColor={[bgColor || isRecommendations ? bgColor : "brand.offWhite"]}
        as="section"
        pt={isMultiple || isMinimal ? 0 : [9, 16]}
        pb={isMultiple || isMinimal ? 0 : [12, 20]}
        textAlign="center"
        {...extraOverflowParentProps}
      >
        <Container className="featProductsContainer">
          <HStack justifyContent={isMinimal ? ["flex-start"] : ["center", "center", "space-between"]} mb={isMultiple ? [4, 12] : [4, 8]}>
            {!isMultiple && (
              <>
                {isMinimal ? (
                  <Text as="p" size={["md"]} variant="bold" p={bgColor === "brand.offWhite" ? [0, 0, 0] : [2, 2, 2]}>
                    {title}
                  </Text>
                ) : (
                  <Heading as="p" fontSize={["2xl", "3xl", "8xl"]}>
                    {title}
                  </Heading>
                )}

                {(!isRecommendations || location?.pathname === "/cart") && button}
              </>
            )}
          </HStack>
          <CarouselOverflowWrapper>
            <Swiper modules={[Navigation]} navigation pagination={{ clickable: true }} slidesPerView="auto">
              {products?.map((item: any, index: number) => (
                <SwiperSlide key={isMinimal ? `minimal-${index}-${item?.id}` : item?.id}>
                  <ProductCard
                    key={item?.id?.toString()}
                    item={item}
                    isPlp
                    handleTrackingClick={handleTrackingClick}
                    location={location}
                    wishListButtonProps={wishListButtonProps}
                    px={[4, 0]}
                    bgColor={isMinimal ? "transparent" : bgColor}
                    maxWidthDesktop="500"
                    maxWidthMobile="500"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </CarouselOverflowWrapper>
        </Container>
      </Box>
    </AlgoliaSearchProvider>
  )
}

const MemoFeaturedProductsWrapper = memo(FeaturedProductsWrapper)
export { MemoFeaturedProductsWrapper as FeaturedProductsWrapper }
